<template>
  <div class="border-b border-gray-200 flex items-center py-4">
    <div class="flex-grow flex flex-col">


      <status-message :response="updateResponse" v-model:message="statusCode" :timeout="3000"></status-message>
      <div>

        <form v-if="editing && editable" @submit.prevent="update" class="flex items-center border border-gray-300 rounded-lg shadow">

          <div class="flex flex-col justify-center border w-full items-center py-8">
            <div class="mb-2 text-lg font-bold">Update <i>{{label}}</i></div>

            <div class="flex mb-4">
              <div v-if="name == 'gender'" class="flex gap-3">

                <label>
                  <input type="radio" :name="name" value="1" class="mx-1"  v-model="updatedValue">
                  <span>Male</span>
                </label>

                <label>
                  <input type="radio" :name="name" value="2"  class="mx-1"  v-model="updatedValue">
                  <span>Female</span>
                </label>

                <label>
                  <input type="radio" :name="name" value="3"  class="mx-1" v-model="updatedValue">
                  <span>Other</span>
                </label>

              </div>

              <div v-else-if="name== 'date_of_birth'">
                  <datepicker class="border border-red-300 rounded py-1 px-3 w-64" v-model="updatedValue"/>
              </div>

              <div v-else>
                <input type="text"  class="border border-gray-300 rounded py-2 px-3  w-64" v-model="updatedValue">
              </div>

            </div>

            <div class="flex justify-center w-full">
              <btn variant="success" class="mr-3">Done</btn>
              <btn @click.prevent="editing=false" variant="danger-outline">Cancel</btn>
            </div>
          </div>

        </form>

        <div v-else>
          <div>{{label}} </div>
          <div v-if="!editing">{{ getValue }}</div>
        </div>

      </div>

    </div>
    <div>
      <btn v-if="!editing && editable" variant="info-outline" @click="enableEditing">Edit</btn>
    </div>
  </div>
</template>

<script>
import Btn from "../../../../components/btn";
import Datepicker from 'vue3-datepicker';
import DateTime from "../../../../core/date-time";
import StatusMessage from "../../../../components/status-message";

export default {
  components: {StatusMessage, Btn, Datepicker},
  data(){
    return  {
      loading: '',
      updatedValue: '',
      editing: false,
      updateResponse: {},
      statusCode: ' ',
    }
  },
  computed:{
    getValue(){
      switch (this.name) {
        case "gender":
          const genders = ['','Male','Female', 'Others'];
          return genders[this.val];
        case "date_of_birth":
          if( !this.val ) return '';
          return new DateTime(this.val).format('{d} {M} {Y}');
        default:
          return this.val

      }
    }
  },
  props: {
    val: {   default: '' },
    name: { type: String, default: 'rsffsaff' },
    label: { type: String, default: '' },
    editable: { type: Boolean, default: true },
  },
  name: "profile-item",
  mounted( ) {
    // this.updatedValue = this.val;
  },
  created() {

  },
  methods:{
    enableEditing(){
      if( this.name == 'date_of_birth') {
        if( this.val ){
          this.updatedValue = new Date();
        }
        this.updatedValue = new DateTime( this.val );
      } else  {
        this.updatedValue = this.val;
      }

      this.editing = true;
    },
    update(){

      let value = null;

      this.loading = true;

      if( this.name == 'date_of_birth' ) {
        value = (new DateTime(this.updatedValue)).format('{Y}-{m}-{d}');
      }else {
        value = this.updatedValue;
      }


      this.$store.dispatch( 'student/editProfileData', {
        key: this.name,
        data: { value },
        response: ( res ) => this.updateResponse = res,
        success: ( { data } )=> {
          this.profile_data = data.student;
          this.editing = false;
          this.$emit( "updated", data )
        }
      }).finally( () => this.loading = false );

    }
  }

}
</script>

<style scoped>

</style>