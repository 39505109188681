

class DateTime extends Date {
    constructor(timestamp) {
        super(timestamp);
    }
}


function format( time, string ) {

    if( typeof string != 'string' ) {
        console.error( 'Invalid! cannot format type of ' + typeof string )
        return '';
    }

    const months = ["January","February","March","April","May", "June", "July","August","September", "October","November","December"]
    const sortMonths = ["Jan","Feb","Mar","Apr","May", "Jun", "Jul","Aug","Sep", "Oct","Nov","Dec"]

    const H = time.getHours( );
    const i = time.getMinutes( );
    const s = time.getSeconds( );
    const dateVars = {
        Y : time.getFullYear(),
        y : time.getYear(),
        h : H === 0 ? '12'
            : (  ( H % 12 ) < 10 ? '0' + ( H % 12 ) : ( H % 12 ) ),
        H : H < 10 ? '0' + H : H,
        i : i < 10 ? '0'+ i : i,
        s : s < 10 ? '0'+ s : s,
        A : ( H >= 12 ? 'PM' : 'AM' ),
        a : ( H >= 12 ? 'pm' : 'am' ),
        m : time.getMonth( ) + 1,
        F : months[ time.getMonth( ) ],
        M : sortMonths[ time.getMonth( ) ],
        d : time.getDate( ),
        ms : time.getMilliseconds( ),
    }



    return  string.replace(/\{(Y|M|F|m|d|h|H|i|s|ms|a|A)\}/g, ( m, g ) => dateVars[g] || '' );

}

DateTime.prototype.format = function ( string ){
    return format( this, string );
};

export { format };

export default DateTime;